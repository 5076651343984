/* global axios */
import ApiClient from './ApiClient';

class AutomationExternalAPI extends ApiClient {
  constructor() {
    super('automation_externals', { accountScoped: true });
  }

  import() {
    return axios.post(`${this.url}/import`);
  }

  execute({ automationId, conversationIds }) {
    return axios.post(`${this.url}/${automationId}/execute`, {
      conversation_ids: conversationIds,
    });
  }
}

export default new AutomationExternalAPI();
