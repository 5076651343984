const Typebot = () => import('./Index.vue');
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/typebot-bots'),
      name: 'typebot_bots',
      meta: {
        permissions: ['administrator'],
      },
      component: Typebot,
    },
  ],
};
