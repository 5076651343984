<script>
import LoadingState from 'dashboard/components/widgets/LoadingState.vue';
export default {
  name: 'StandaloneAppFrame',
  components: {
    LoadingState,
  },
  props: {
    config: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      iframeLoading: true,
    };
  },
  computed: {
    standaloneAppContext() {
      return {
        currentAgent: this.currentAgent,
      };
    },
    currentAgent() {
      const { id, name, email } = this.$store.getters.getCurrentUser;
      return { id, name, email };
    },
  },
  mounted() {
    window.onmessage = e => {
      if (
        typeof e.data !== 'string' ||
        e.data !== 'chatwoot-standalone-app:fetch-info'
      ) {
        return;
      }
      this.onIframeLoad(0);
    };
  },
  methods: {
    onIframeLoad(index) {
      const frameElement = document.getElementById(
        `standalone-app--frame-${index}`
      );
      const eventData = {
        event: 'appContext',
        data: this.standaloneAppContext,
      };
      frameElement.contentWindow.postMessage(JSON.stringify(eventData), '*');
      this.iframeLoading = false;
    },
  },
};
</script>

<template>
  <div class="standalone-app--container">
    <div
      v-for="(configItem, index) in config"
      :key="index"
      class="standalone-app--list"
    >
      <LoadingState
        v-if="iframeLoading"
        :message="$t('DASHBOARD_APPS.LOADING_MESSAGE')"
        class="standalone-app_loading-container"
      />
      <iframe
        :id="`standalone-app--frame-${index}`"
        :src="configItem.url"
        @load="() => onIframeLoad(index)"
      />
    </div>
  </div>
</template>

<style scoped>
.standalone-app--container,
.standalone-app--list,
.standalone-app--list iframe {
  height: 100%;
  width: 100%;
}
.standalone-app--list iframe {
  border: 0;
}
.standalone-app_loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
</style>
