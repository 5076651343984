/* global axios */
import ApiClient from '../ApiClient';

class WhatsAppBridgeChannel extends ApiClient {
  constructor() {
    super('inboxes', { accountScoped: true });
  }

  importContact(id) {
    return axios.get(`${this.url}/${id}/import_contact`);
  }
}

export default new WhatsAppBridgeChannel();
