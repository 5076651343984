import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import AutomationExternalAPI from '../../api/automationExternal';
import { throwErrorMessage } from '../utils/api';

export const state = {
  records: [],
  uiFlags: {
    isImporting: false,
    isExecuting: false,
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
  },
};

export const getters = {
  getAutomations(_state) {
    return _state.records;
  },
  getAutomationsActive(_state) {
    return _state.records.filter(f => f.active);
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
};

export const actions = {
  get: async function getAutomations({ commit }) {
    commit(types.SET_EXTERNAL_AUTOMATION_UI_FLAG, { isFetching: true });
    try {
      const response = await AutomationExternalAPI.get();
      commit(types.SET_EXTERNAL_AUTOMATIONS, response.data.payload);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_EXTERNAL_AUTOMATION_UI_FLAG, { isFetching: false });
    }
  },
  create: async function createAutomation({ commit }, automationObj) {
    commit(types.SET_EXTERNAL_AUTOMATION_UI_FLAG, { isCreating: true });
    try {
      const response = await AutomationExternalAPI.create(automationObj);
      commit(types.ADD_EXTERNAL_AUTOMATION, response.data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_EXTERNAL_AUTOMATION_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_EXTERNAL_AUTOMATION_UI_FLAG, { isUpdating: true });
    try {
      const response = await AutomationExternalAPI.update(id, updateObj);
      commit(types.EDIT_EXTERNAL_AUTOMATION, response.data.payload);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_EXTERNAL_AUTOMATION_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_EXTERNAL_AUTOMATION_UI_FLAG, { isDeleting: true });
    try {
      await AutomationExternalAPI.delete(id);
      commit(types.DELETE_EXTERNAL_AUTOMATION, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_EXTERNAL_AUTOMATION_UI_FLAG, { isDeleting: false });
    }
  },
  import: async ({ commit }) => {
    commit(types.SET_EXTERNAL_AUTOMATION_UI_FLAG, { isImporting: true });
    try {
      await AutomationExternalAPI.import();
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_EXTERNAL_AUTOMATION_UI_FLAG, { isImporting: false });
    }
  },
  execute: async ({ commit }, automationObj) => {
    commit(types.SET_EXTERNAL_AUTOMATION_UI_FLAG, { isExecuting: true });
    try {
      await AutomationExternalAPI.execute(automationObj);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_EXTERNAL_AUTOMATION_UI_FLAG, { isExecuting: false });
    }
  },
};

export const mutations = {
  [types.SET_EXTERNAL_AUTOMATION_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.ADD_EXTERNAL_AUTOMATION]: MutationHelpers.create,
  [types.SET_EXTERNAL_AUTOMATIONS]: MutationHelpers.set,
  [types.EDIT_EXTERNAL_AUTOMATION]: MutationHelpers.update,
  [types.DELETE_EXTERNAL_AUTOMATION]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
